import React from 'react'

export const AttendanceMock = () => {
  return (
    <div className="attendance-container">
      <p>
        Entre em contato conosco para sugestões e opiniões diretamente pelo
        e-mail:
      </p>
      <p>
        <a href="mailto: faleconosco@superpao.com.br">
          <strong>faleconosco@superpao.com.br</strong>
        </a>
      </p>
    </div>
  )
}
